import React from 'react';
import Layout from '../components/Layout';

const About = () => {
  return (
    <Layout className="contact-success">
      <section>
        <div>
          <h1>Thank you for reaching out</h1>
          <p>We'll be in touch soon.</p>
        </div>
      </section>
    </Layout>
  );
};

export default About;